var Backbone = require('lib/jqBackbone');
var $ = require('lib/FragQuery');
var spinnerFactory = require('lib/spinnerFactory.js');
var CardView = require('views/blog/Card.js');
var BlogLoader = Backbone.View.extend({

    $initiator: null,
    $container: null,
    nextPage: 2,
    limit: 6,
    spinner: null,

    events: {

    },

    initialize: function(options) {
        this.$initiator = this.$el.find(options.initiatorSelector);
        this.$container = this.$el.find(options.containerSelector);
        
        this.events['click ' + options.initiatorSelector] = 'load';
        this.delegateEvents();
    },

    load: function(event) {
        var vm = this;
        event.preventDefault();
        this.placeSpinner();

        $.ajax({
            url: '/api/articles.json',
            type: 'GET',
            dataType: 'json',
            data: {
                limit: this.limit,
                page: this.nextPage      
            },
            complete: function (jqXHR, textStatus) {
            },
            success: function (data, textStatus, jqXHR) {
                var cards = vm.createCards(data.articles);
                $.each(cards, function(index, card) {
                    vm.$container.append(card.$el);
                    card.open();
                });
                vm.nextPage++;
                vm.liftSpinner();
            },
            error: function (jqXHR, textStatus, errorThrown) {
                if (jqXHR.status === 404) {
                    vm.$initiator.remove();
                    vm.spinner.stop();
                } else {
                    alert('Woops! There was a problem grabbing more articles');
                }
            }
        });
    },

    createCards: function(cardData) {
        var cards = [];

        $.each(cardData, function(index, data) {
            cards.push(new CardView({
                data: data
            }));
        });

        var boxes = $('.blog-post');
        maxHeight = Math.max.apply(
          Math, boxes.map(function() {
            return $(this).height();
        }).get());
        boxes.height(maxHeight);

        return cards;
    },

    placeSpinner: function() {
        this.$initiator.css('visibility', 'hidden');
        this.spinner = spinnerFactory(this.$initiator.parent(), '15%');
    },

    liftSpinner: function() {
        this.$initiator.removeAttr('style');
        this.spinner.el.remove();
    }

});

module.exports = BlogLoader;
