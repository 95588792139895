var Backbone = require('lib/jqBackbone.js');
var BsNotify = require('lib/BsNotify.js');
var spinnerFactory = require('lib/spinnerFactory.js');

module.exports = Backbone.View.extend({
    
    events: {
        'click [data-method="validateNumber"]': 'validateNumber',
    },

    bindings: {
        '#InvoiceNumber': 'number',
        '#InvoiceTotal': 'total'
    },

    initialize: function() {
        this.stickit();
        this.bindEvents();
        this.notify = new BsNotify();
    },

    validateNumber: function(event) {
        this.model.validate();
    },

    placeSpinner: function() {
        this.spinner = spinnerFactory(this.$el);
    },

    bindEvents: function() {
        var vm = this;
        this.model.on('valid', function(total) {
            vm.notify.success(vm.$el.find('#InvoiceNumber'), 'YAY!');
            vm.$el.find('#PreValid').slideUp(400, function() {
                vm.$el.find('#PostValid').slideDown();
            });
        });
        this.model.on('invalid', function(number) {
            vm.notify.error(vm.$el.find('#InvoiceNumber'), 'Invoice number invalid');
        });
        this.model.on('complete', function() {
            vm.spinner.el.remove();
            vm.$el.find('#Complete').slideDown();
        });
        this.model.on('attempt', function() {
            vm.$el.find('#PostValid').slideUp(400, function() {
                vm.placeSpinner();
            });
        });
    },
    
});
