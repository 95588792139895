var JumboInvoice = require('views/billing/JumboInvoice.js');
var InfoForm = require('views/billing/InfoForm.js');
var Invoice = require('models/billing/Invoice');
var $ = require('jquery');

function billingController(options) {

    var vm = this;
    return this;

}

// Invoice Pay Action
billingController.prototype.pay = function(options) {

    var invoice = new Invoice({});

    var jumboInvoice = new JumboInvoice({
        el: $('.jumbotron'),
        model: invoice
    });

    var infoForm = new InfoForm({
        el: $('#InfoForm'),
        jumboInvoice: jumboInvoice,
        model: invoice
    });

};

module.exports = function(options) {
    return new billingController(options);
};
