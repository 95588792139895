var Backbone = require('lib/jqBackbone');
var $ = require('lib/FragQuery.js');

module.exports = Backbone.View.extend({
    
    events: {
        'submit': 'attemptPayment'
    },

    initialize: function(options) {
        console.log($);
        this.model = options.model;
        this.bindEvents();
    },

    bindEvents: function() {
        var vm = this;
        this.model.on('valid', function(total) {
            vm.toggleInputs(true);
        });
        this.model.on('complete', function() {
            vm.toggleInputs();
        });
    },

    toggleInputs: function(enable) {
        if (enable === true) {
            this.$el.find('input').enable();
        } else {
            this.$el.find('input').disable();
            this.$el.find('#BillingInformation').slideUp();
        }
    },

    formIsValid: function() {
        var $button = this.$el.find('[type="submit"]');
        return !$button.hasClass('disabled');
    },

    attemptPayment: function(event) {
        var vm = this;
        event.preventDefault();

        if (!this.formIsValid()) {
            return;
        }

        vm.model.trigger('attempt');
        $.ajax({
            url: '/billing/pay.json',
            type: 'POST',
            data: vm.$el.serialize(),
            complete: function (jqXHR, textStatus) {
                // callback
            },
            success: function (data, textStatus, jqXHR) {
                if (data.success === true) {
                    vm.model.trigger('complete');
                } else {
                    vm.model.trigger('failure');
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                // error callback
            }
        });
    },


});
