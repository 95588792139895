Backbone = require('lib/jqBackbone.js');

function routerFactory(routes) {
    var Router = Backbone.Router.extend({
        initialize: function(options) {
            routes(this);            
        }
    });
    var router = new Router({});

    /**
     * We need to make sure the history api is available
     * before enabling pushState. Otherwise, the router
     * will explode in > IE9
     */
    if (window.history && window.history.pushState) {
        Backbone.history.start({pushState: true});
    } else {
        Backbone.history.start({pushState: true, hashChange: false});
    }

    return router;
}



module.exports = routerFactory;
