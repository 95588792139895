var BlogLoader = require('lib/BlogLoader');
var $ = require('lib/FragQuery');

function blogController(options) {
    var vm = this;
    return this;
}

blogController.prototype.index = function(options) {
    var blogLoader = new BlogLoader({
        el: $('#blog'),
        initiatorSelector: '[data-blogLoader="initiator"]',
        containerSelector: '[data-blogLoader="container"]'
    });
};

module.exports = function(options) {
    return new blogController(options);
};
