var Backbone = require('lib/jqBackbone.js');
var $ = require('jquery');
var numeral = require('numeral');

module.exports = Backbone.Model.extend({

    defaults: {
        number: '',
        total: 0.00,
    },

    initialize: function() {
    },

    bindEvents: function() {
        this.on('change:number', function(oldVal, newVal) {
        });
    },

    validate: function() {
        var vm = this;

        $.ajax({
            url: '/api/payments/0.json?invoice_number=' + this.get('number'),
            type: 'GET',
            dataType: 'json',
            complete: function (jqXHR, textStatus) {
            },
            success: function (data, textStatus, jqXHR) {
                if (data.payment === null) {
                    vm.validationError = 'Invalid invoice number';
                    vm.trigger('invalid', [vm.get('number')]);
                } else {
                    vm.set('total', numeral(data.payment.amount).format('$0,0.00'));
                    vm.trigger('valid', [vm.get('total')]);
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {

            }
        });
    },

});
