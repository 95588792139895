var $ = require('jquery');
var GoogleMapsLoader = require('google-maps');
var addressLayout = require('templates/addressInfo.html');

function contactController(options) {
    var vm = this;
    return this;
}

/**
 * 
 * [Home Page View]
 *  Services section, with carousel will go here.
 * 
 */
contactController.prototype.view = function(options) {

    GoogleMapsLoader.load(function(google) {
        var options = {
          center: new google.maps.LatLng(35.7955757, -78.6374031),
          zoom: 16,
          scrollwheel: false,
          mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        var map = new google.maps.Map(document.getElementById("map"), options);
        
        var marker = new google.maps.Marker({
          position: {'lat' : 35.7955757, 'lng': -78.6374031},
          map: map,
          icon:  '/img/fragment-flag.png'
        });
        
        var infowindow = new google.maps.InfoWindow({
            content: addressLayout
        });
        
        infowindow.open(map,marker);

        map.set('styles', [
          {
            "featureType": "poi",
            "stylers": [
              { "visibility": "off" }
            ]
          },{
            "featureType": "road",
            "stylers": [
              { "visibility": "simplified" },
              { "color": "#009688" },
              { "weight": 1 }
            ]
          },{
            "featureType": "road",
            "elementType": "labels.text",
            "stylers": [
              { "color": "#000000" },
              { "weight": 1 },
              { "visibility": "simplified" }
            ]
          },{
            "featureType": "landscape",
            "stylers": [
              { "color": "#eeeeee" },
              { "visibility": "on" },
            ]
          },{
            "featureType": "transit",
            "stylers": [
              { "visibility": "off" }
            ]
          },{
            "featureType": "road",
            "elementType": "labels.icon",
            "stylers": [
              { "visibility": "off" }
            ]
          }
        ]);
    });
    $('#ContactUsButton').remove();
};

module.exports = function(options) {
    return new contactController(options);
};
