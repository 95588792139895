var factory = require('core/routerFactory.js');

/**
 * Define your routes here
 */
router = factory(function(vm) {

    // vm.route('example/derp/derp', function() {
    //     //do stuff
    // });

    vm.route('contact', function() {
        require('controllers/contactController.js')().view();
    });

    vm.route('billing/pay', function() {
        require('controllers/billingController.js')().pay();
    });
    
    vm.route('blog', function() {
        require('controllers/blogController.js')().index();
    });

});

module.exports = router;
