var Backbone = require('lib/jqBackbone');
var $ = require('lib/FragQuery.js');
var templateString = require('templates/card.html');
var _ = require('underscore');

module.exports = Backbone.View.extend({

    events: {

    },

    initialize: function(options) {
        this.render(options.data);
        this.delegateEvents();
    },

    render: function(data) {
        var template = _.template(templateString);
        this.el = template(data);
        this.$el = $(this.el);
    },

    open: function() {
        this.$el.slideDown(300);
    }
});
