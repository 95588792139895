var $ = require('jquery');

// Global vars for bootstrap's js
window.$ = $;
window.jQuery = $;

// jQuery Plugins
require('bootstrap-validator-commonjs');
require('bootstrap');

$.fn.enable = function() {
    this.removeAttr('disabled');
};

$.fn.disable = function() {
    this.attr('disabled', 'disabled');
};

module.exports = $;
