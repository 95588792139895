var $ = require('jquery');

function BsNotify(argument) {
    
}

BsNotify.prototype.error = function($element, message) {
    notify($element, message, 'has-error');
};

BsNotify.prototype.success = function($element, message) {
    notify($element, message, 'has-success');
};

function notify($element, message, className) {
    var $formGroup = $element.parents('.form-group');

    $formGroup.addClass(className);

    if ($formGroup.find('label').length === 0) {
        $formGroup.append(buildLabel(message));
    }
};


function buildLabel(text) {
    var $label = $('<label>' + text + '</label>');
    $label.addClass('control-label');
    return $label;
}

module.exports = BsNotify;
